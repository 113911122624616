import { AnimatePresence } from "framer-motion";

function AnimatePresenceComponent({ children }) {
  return (
    <AnimatePresence mode='wait'>
      {children}
    </AnimatePresence>
  );
}

export default AnimatePresenceComponent;
