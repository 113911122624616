import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import Overlay1 from './overlays/overlay1';
import Overlay2 from './overlays/overlay2';
import Overlay3 from './overlays/overlay3';
import Overlay4 from './overlays/overlay4';



function ParallaxComponent() {
  const images = ['/img/library.webp', '/img/laptop.webp', '/img/gaming.webp', '/img/letter.webp'];
  const [scrollY, setScrollY] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [, setScrollDirection] = useState('down');


  useEffect(() => {
    const handleScroll = () => {
      const newScrollY = window.scrollY;
      setScrollDirection(newScrollY > scrollY ? 'down' : 'up');
      setScrollY(newScrollY);
      const newImageIndex = Math.floor(newScrollY / window.innerHeight * 1.5);
      console.log(`newScrollY: ${newScrollY}, newImageIndex: ${newImageIndex}, currentIndex: ${currentIndex}`);
      if (newImageIndex !== currentIndex && newImageIndex >= 0 && newImageIndex < images.length) {
        setCurrentIndex(newImageIndex);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollY, currentIndex, setScrollDirection, images.length]);


  const renderOverlay = () => {
    switch (currentIndex) {
      case 0:
        return <Overlay1 />;
      case 1:
        return <Overlay2 />;
      case 2:
        return <Overlay3 />;
      case 3:
        return <Overlay4 />;
      default:
        return null;
    }
  };

  return (
    <div style={{ height: '400vh', position: 'relative' }}>
      {[currentIndex - 1, currentIndex, currentIndex + 1].map(index => (
        index >= 0 && index < images.length && (
          <motion.img
            key={index}
            src={images[index]}
            initial={false}
            animate={{ y: index === currentIndex ? 0 : (index < currentIndex ? -window.innerHeight : window.innerHeight) }}
            transition={{ ease: "easeInOut", duration: 1 }}
            style={{ width: '100%', height: '100vh', objectFit: 'cover', position: 'fixed', top: 0, zIndex: 900 }}
          />
        )
      ))}
      <div style={{ position: 'fixed', top: 0, zIndex: 1000 }}>
        {renderOverlay()}
      </div>
    </div>
  );
}

export default ParallaxComponent;
