// Project.js
import React, { useState, useRef, useEffect } from 'react';
import './Project.css';

function Project({ staticImg, webmImg, altText, title, repoUrl, demoLink, additionalContentComponent }) {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    if (isHovered) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [isHovered]);

  return (
    <div className="project-container">
      <h2 className="project-title">{title}</h2>
      <div
        className="project-img-box"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img className="project-media" src={staticImg} alt={altText} style={{ display: isHovered ? 'none' : 'block' }} />
        <video className="project-media" src={webmImg} alt={altText} ref={videoRef} loop muted style={{ display: isHovered ? 'block' : 'none' }} />
      </div>
      {isHovered && (
        <div>
          {repoUrl && <a href={repoUrl}>GitHub Repo</a>}
          {demoLink && <a href={demoLink}>URL</a>}
          {/* Render the additional content component if provided */}
          {additionalContentComponent}
        </div>
      )}
    </div>
  );
}

export default Project;