// Overlay1.js
import React, { useState, useEffect } from 'react';
import '../overlays/overlay1.css';


function Overlay1() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    const handleLoad = () => setIsLoading(false);
    window.addEventListener('load', handleLoad);
    return () => window.removeEventListener('load', handleLoad);

    }, []); 

    useEffect(() => {
      setIsLoading(false);
    }, []);

  return (
    <div className="overlay">
      <h1>Hi I'm Sean B.</h1>
      <p>Check out my portfolio</p>
      <div className="arrow">
        {isLoading ? (
          <div className="spinner"></div>
        ) : (
          <>
        
        <span></span>
        <span></span>
        <span></span>
        </>
        )}
      </div>
    </div>
  );
}

export default Overlay1;