// Overlay2.js
import React from 'react';
import '../overlays/overlay3.css';
import Project from '../components/Project.js';
// import AdditionalContent from '../components/additionalContentLinks.js';

function Overlay2() {
  return (
    <div className="overlay2">
      <Project
        staticImg="../project-img/bun2webp.webp"
        webmImg="../project-img/convert2webp.webm"
        altText=""
        title="Bun2WebP"
        repoUrl="https://github.com/slbillups/bun2webp"
        demoLink="https://github.com/slbillups/bun2webp"
      />
  
      <Project
        staticImg="../project-img/ynh.webp"
        webmImg="../project-img/ynh.webm"
        altText="Domain Hosting & VPS Maintenance"
        title="Domain hosting"
        demoLink="additionalContent"
        // additionalContentComponent={<AdditionalContent />}
      />

      <Project
        staticImg="../project-img/irish.webp"
        webmImg="../project-img/irish.webm"
        altText="Domain Hosting & VPS Maintenance"
        title="Transcription & VO"
        demoLink="http://link-to-your-project1.com"
      />
    </div>
  );
}

export default Overlay2;