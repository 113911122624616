import React from 'react';
import './App.css';
import AnimatePresenceComponent from './AnimatePresence';
import ParallaxComponent from './ParallaxComponent';

function App() {
  return (
    <div className="App">
      <AnimatePresenceComponent>
        <ParallaxComponent />
      </AnimatePresenceComponent>
    </div>
  );
}

export default App;
