// src/components/Overlays/Overlay3.js
import React from 'react';
import '../overlays/overlay2.css'; // Import overlay2.css
import Project from '../components/Project.js';

function Overlay3() {
  return (
    <div className="overlay2"> {/* Apply the overlay2 class */}
      <Project
        staticImg="../project-img/bun2webp.webp"
        webmImg="../project-img/convert2webp.webm"
        altText=""
        title="Unity/UE5 Character Rigging & Animation with the help of Blender"
        repoUrl="https://github.com/slbillups/bun2webp"
        demoLink="https://github.com/slbillups/bun2webp"
      />
  
      <Project
        staticImg="../project-img/ynh.webp"
        webmImg="../project-img/ynh.webm"
        altText="Domain Hosting & VPS Maintenance"
        title="Domain hosting"
        demoLink="additionalContent"
      />

      <Project
        staticImg="../project-img/irish.webp"
        webmImg="../project-img/irish.webm"
        altText="Domain Hosting & VPS Maintenance"
        title="Transcription & VO"
        demoLink="http://link-to-your-project1.com"
      />
    </div>
  );
}

export default Overlay3;