// src/components/Overlays/Overlay4.js
import React from 'react';
import './overlay4.css';

function Overlay4() {
  return (
    <div className="overlay">
        <h1>Contact Me</h1>
        <p>Phone: <a href="tel:972-345-1642"><img className="phonenum" src='/icons/phonenum.svg' alt='phone svg icon'/></a></p>
        <p>Email: <a href="mailto:slbillups@pm.me"><img className="email-icon" src='/icons/mail.svg' alt='email svg icon'/></a></p>
      </div>
  );
}

export default Overlay4;